import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  useReducer,
} from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { COLUMNS } from "./Columns";
import "./filtering.css";
import {
  getDetalleFacturaCompraId,
  getFacturasComprasByQuery,
} from "../../../Api/facturacion";

import Swal from "sweetalert2";
import ModalReporteCompra from "../sis/ModalOpciones/ModalReporteCompra";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
        TimbradoObject: state.TimbradoObject,
      };
    case "END":
      return { ...curShow, isShow: false };
    default:
      return { ...curShow, isShow: false };
  }
};
const Reportecompras = () => {
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    TimbradoObject: null,
    onReturnValue: null,
  });
  const compraventa="none";
  const rformadepago="Obs";
  const [filterData, setValue] = useState([]);
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const data3 = useRef();
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const Filtrar = async () => {
    try {
      if (filterData.fechaFin == "") {
        filterData.fechaFin = "1991-01-01";
      }
      if (filterData.fechaInicio == "") {
        filterData.fechaInicio = "1991-01-01";
      }
      const result = await getFacturasComprasByQuery(filterData);
      setdata([...result]);
    } catch (err) {
      console.log(err);
    }
  };

  const onAgregarTimbrado = async () => {
    Swal.fire({
      title: "Generando",
      html: "Generando Reporte", // add html attribute if you want or remove
      allowOutsideClick: false,
      showCancelButton: true,
      showConfirmButton: false,
    });
    const producto = {
      fechaInicio: filterData.fechaincial,
      fechaFin: filterData.fechafinal,
    };
    const result = await getFacturasComprasByQuery(producto);
    
    setdata2([...result]);
    let productox = "";
    let datas = [];
    for (let j = 0; j < result.length; j++) {
      const result2 = await getDetalleFacturaCompraId(result[j].idFactura );
      
      for (let i = 0; i < result2.length; i++) {

        
        if (i<result2.length && i!=result2.length-1)
        {
          productox=productox+result2[i].cantidad+" "+result2[i].producto+" , "
        }
        else
        {
          productox=productox+result2[i].cantidad+" "+result2[i].producto+"."
        }
      }
      datas.push({
        idfactura: result[j].idFactura,
        producto: productox,
      });
      productox = '';
    }
    console.log(datas)
    data3.current = datas;
    Swal.close();
    dispatchReducer({
      type: "SHOW",
    });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  return (
    <Fragment>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-auto">
                <Card.Title>Reporte Facturas Provedores</Card.Title>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-auto">
                <Card.Title>Reporte</Card.Title>
              </div>
              <div className="chart-wrapper">
                <form id="auFORM">
                  <Row className="mb-3">
                    <Col className="xl-4">
                      <div className="form-group mb-2 ">
                        <label className="col-form-label">Fecha Inicial</label>
                        <div className="">
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setValue({
                                ...filterData,
                                fechaincial: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <label className="col-form-label">Fecha Final</label>
                        <div className="">
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setValue({
                                ...filterData,
                                fechafinal: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="xl-4"></Col>
                    <Col className="xl-4"></Col>
                  </Row>
                  <Button onClick={onAgregarTimbrado} className="mb-4 ml-2">
                    Generar Reporte
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    

      {reducer.isShow && (
        <ModalReporteCompra
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          data={data2}
          data2={data3}
          date={filterData.fechaincial}
          date2={filterData.fechafinal}
          compraventa={compraventa}
          formadepago={rformadepago}
        />
      )}
    </Fragment>
  );
};
export default Reportecompras;
