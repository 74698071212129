import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import ModalAgregarClientes from "./sis/ModalFacturacion/ModalAgregarCliente";
import {
  getAllClientes,
  getClienteById,
  saveCliente,
} from "../../Api/cliente.js";
import { getAllFormaPago } from "../../Api/formaPago";
import { getAllTimbrados, getTimbradoByQuery } from "../../Api/timbrado";
import { getAllPuntoVenta } from "../../Api/puntoVenta";
import { getAllSucursal } from "../../Api/sucursal";
import Swal from "sweetalert2";
import { TYPE_FACTURA } from "../../Utils/const";
import TableRows from "./TableRows";
import { insertFactura } from "../../Api/facturacion";
import { useNavigate } from "react-router-dom";
import { getAllCostos, getCostosbyID } from "../../Api/Costos";
import { getUserCredentials } from "../../Api/usuario";
import ModalPreview from "./Preview";
import genPDF from "./pdfWriter"
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "SHOW2":
      return {
        isShow2: true,
        typeModal: state.typeModal,
        onReturnValue2: state.onReturnValue2,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };
    default:
      return { ...curShow, isShow: false };
  }
};
const Generarfactura = () => {
  const Editdata = [{
    nombre:"",
    razonsocial:"",
    ruc:"",
    Ciudad:"",
    direccion:"",
    telefono:"",
    correo:""
  }]

  const Compro = useRef(null)
  const fdp = useRef(null)
  const tp = useRef(null)
  const [Comprobante, setComprobante] = useState(true);
  const [Descuento, setDescuento] = useState(0);
  const [Precio, setPrecio] = useState();
  const [Contado, setContado] = useState(false);
  const [Credito, setCredito] = useState(false);
  const Ivafive = useRef(0);
  const Ivaten = useRef(0);
  const descuentototal = useRef(0)
  const [Ivaproducto, setIvaproducto] = useState();
  const Tim = useRef(0);
  const Suc = useRef(0);
  const PDV = useRef(0);
  const nfactura = useRef();
  const timbradoid = useRef();
  const navigate = useNavigate();
  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      preciosindescuento:0,
      descuento:0,
      cantidad: 0,
      producto: "",
      precio: "",
      iva: "",
      total: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    
    
    const rows = [...rowsData];
    rows.splice(index, 1);
    if (formData3.descuento === undefined) {
      formData3.descuento = 0;
    }
    const _ = require("lodash");
    
    const sum = _.sumBy(rows, (rowsDatas) => Number(rowsDatas.total));
    const subtotal5 = _.sumBy(rows, (rowsDatas) => Number(rowsDatas.cinco));
    const subtotal10 = _.sumBy(rows, (rowsDatas) => Number(rowsDatas.diez));
    let i;
    let iva10 = 0;
    let iva5 = 0;
    for (i in rows) {
      if (rows[i].iva == 5) iva5 = iva5 + rows[i].total / 21;
      else iva10 = iva10 + rows[i].total / 11;
    }
    
    descuentototal.current=((sum * formData3.descuento) / 100);
    totalfactura.current=(sum - (sum * formData3.descuento) / 100);
    Ivaten.current=(Math.round(iva10));
    Ivafive.current=(Math.round(iva5));
    ivatotalfactura.current=(Math.round(iva5)+Math.round(iva10));
    setValue({
      ...formData3,
      totalmonto: sum - (sum * formData3.descuento) / 100,
      descuentomonto: (sum * formData3.descuento) / 100,
      iva10: Math.round(iva10),
      iva5: Math.round(iva5),
      ivatotal: Math.round(iva5)+Math.round(iva10),
      Subtotal5:subtotal5,
      Subtotal10:subtotal10,
    });
    setRowsData(rows);
  };
  const handleChange2 = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    rowsInput[index]["montodescuento"]=Math.round((rowsInput[index]["descuento"]*rowsInput[index]["preciosindescuento"])/100)
    rowsInput[index]["precio"] = rowsInput[index]["preciosindescuento"] - rowsInput[index]["montodescuento"]
    rowsInput[index]["total"] = Math.round(rowsInput[index]["precio"] * rowsInput[index]["cantidad"]);
    if (rowsInput[index]["iva"]==5)
{
  
  rowsInput[index]["exenta"]=" "
  rowsInput[index]["cinco"]=rowsInput[index]["total"]
  rowsInput[index]["diez"]=" "
}
if (rowsInput[index]["iva"]==10)
{
  rowsInput[index]["exenta"]=" "
  rowsInput[index]["cinco"]=" "
 
  rowsInput[index]["diez"]=rowsInput[index]["total"]
}

    setRowsData(rowsInput);
    Refresh()
   
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    
    rowsInput[index]["total"] = Math.round(rowsInput[index]["precio"] * rowsInput[index]["cantidad"]);
    if (rowsInput[index]["iva"]==5)
{
  
  rowsInput[index]["exenta"]=" "
  rowsInput[index]["cinco"]=rowsInput[index]["total"]
  rowsInput[index]["diez"]=" "
}
if (rowsInput[index]["iva"]==10)
{
  rowsInput[index]["exenta"]=" "
  rowsInput[index]["cinco"]=" "
 
  rowsInput[index]["diez"]=rowsInput[index]["total"]
}

    setRowsData(rowsInput);
    Refresh()
   
  };
  const handleChangeSelect = useCallback(async (index, evnt, name) => {
    Swal.fire({
      title: 'Cargando',
      html: 'Cargando  datos',// add html attribute if you want or remove
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
     
  });
    const ReturnList = await getCostosbyID(evnt.value);
    Swal.close()
    setPrecio(Math.round(ReturnList.precioventa));
    setIvaproducto(ReturnList.ivavalor);
    const rowsInput = [...rowsData];
   
    
    rowsInput[index]["idProducto"] = evnt.value;
    rowsInput[index]["artcod"] = ReturnList.COD;
    rowsInput[index][name] =  ReturnList.producto;
    rowsInput[index]["preciosindescuento"]=ReturnList.precioventa;
    rowsInput[index]["montodescuento"]=Math.round((rowsInput[index]["descuento"]*rowsInput[index]["preciosindescuento"])/100)
    rowsInput[index]["precio"] = Math.round(rowsInput[index]["preciosindescuento"]-((rowsInput[index]["descuento"]*rowsInput[index]["preciosindescuento"])/100))
    rowsInput[index]["iva"] = ReturnList.ivavalor;
    rowsInput[index]["total"] = Math.round(rowsInput[index]["precio"] * rowsInput[index]["cantidad"]);
    
if (ReturnList.ivavalor==5)
{
 
  rowsInput[index]["exenta"]=" "
  rowsInput[index]["cinco"]= rowsInput[index]["precio"] * rowsInput[index]["cantidad"];
  rowsInput[index]["diez"]=" "
}
if (ReturnList.ivavalor==10)
{
  rowsInput[index]["exenta"]=" "
  rowsInput[index]["cinco"]=" "
  rowsInput[index]["diez"]= rowsInput[index]["precio"] * rowsInput[index]["cantidad"];
}
  Refresh()

    setRowsData(rowsInput);
  });


  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
    typeModal: null,
    onReturnValue: null,
  });
  const handleShow4 = () => {
    dispatchReducer({ type: "SHOW", typeModal: "CLIENTE" });
  };
  const handleShow5 = () => {
     
    let errordescuento=0
    let errorcantidad=0
    for (let i=0;i<rowsData.length;i++)
    {
      if  (rowsData[i].descuento=="")
      {
         errordescuento=1
      }
      if  (rowsData[i].cantidad=="" ||rowsData[i].cantidad==0 )
      {
         errorcantidad=1
      }
    }

    formData3.tipoFactura= formData3.tipoFactura?  formData3.tipoFactura:"Contado"
    formData3.formadepago= formData3.formadepago?  formData3.formadepago:"Efectivo"
    if (formData3.numeroActual=== undefined || formData3.numeroActual.current == '') {
      Swal.fire({
        title: "Sin Numero de Factura ",
        html: `Favor Ingrese numero de Factura`,
        icon: "warning",
        confirmButtonColor: "#00273a",
      });

    }
    else if (formData3.ruc === undefined)
    {
      Swal.fire({
        title: "Sin Razon social y Ruc",
        html: `Favor Ingrese Cliente`,
        icon: "warning",
        confirmButtonColor: "#00273a",
      });
    }else if (formData3.totalmonto === undefined || rowsData.length === 0 || rowsData[0].producto=="" || rowsData[0].cantidad=="")
      {
        Swal.fire({
          title: "Sin Detalle ",
          html: `Favor ingrese detalle `,
          icon: "warning",
          confirmButtonColor: "#00273a",
        });

      }else if (errordescuento==1)
      {
        Swal.fire({
          title: "Descuento no puede ser Vacio",
          html: `Ingrese descuento`,
          icon: "warning",
          confirmButtonColor: "#00273a",
        });
      }else if (errorcantidad==1)
      {
        Swal.fire({
          title: "Cantidad no puede ser 0 o Vacio",
          html: `Ingrese Cantidad`,
          icon: "warning",
          confirmButtonColor: "#00273a",
        });
      }
      
      else{
        if (formData3.tipoFactura=="Contado")
        {
            setContado(true)
            setCredito(false)
        }else
        {
          setCredito(true)
          setContado(false)
        }
        dispatchReducer({ type: "SHOW2", typeModal: "CLIENTE" });
      }

  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };

  const timbradoID = useCallback(async (e) => {
    Tim.current = e.label;
    Swal.fire({
      title: 'Cargando',
      html: 'Cargando  datos',// add html attribute if you want or remove
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
     
  });
    const objecttalonario = { Suc, PDV, Tim };
    const resultTimbrado = await getTimbradoByQuery(objecttalonario);
    Swal.close();
    if (resultTimbrado === undefined) {
      nfactura.current = "";
    } else {
      var f1 = new Date(resultTimbrado.fechaFinal);

      if (f1 < new Date()) {
        Swal.fire({
          title: "Talonario Vencido",
          html: `favor de reportar e Inhabilitar`,
          icon: "warning",
          confirmButtonColor: "#00273a",
        });
        navigate("/Opcionesdetimbrado");
      } else {
        nfactura.current = resultTimbrado.numeroActual;
        if (nfactura.current > resultTimbrado.numeroFinal) {
          Swal.fire({
            title: "Talonario Sin Facturas",
            html: `favor de reportar e Inhabilitar`,
            icon: "warning",
            confirmButtonColor: "#00273a",
          });
          navigate("/Opcionesdetimbrado");
        } else {
          if (nfactura.current == resultTimbrado.numeroFinal) {
            Swal.fire({
              title: "Ultima Factura del talonario",
              html: `favor de reportar`,
              icon: "warning",
              confirmButtonColor: "#00273a",
            });
          }
        }
      }

      timbradoid.current = resultTimbrado.idtimbrado;
    }

    setValue({
      ...formData3,
      timbrado: Tim.current,
      idtimbrado: timbradoid.current,
      pf1: Suc.current,
      pf2: PDV.current,
      numeroActual: nfactura,
      moneda: "Gs",
    });
  });
  const sucursalID = useCallback(async (e) => {
    Suc.current = e.value;
    Swal.fire({
      title: 'Cargando',
      html: 'Cargando  datos',// add html attribute if you want or remove
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
     
  });
    const objecttalonario = { Suc, PDV, Tim };
    const resultTimbrado = await getTimbradoByQuery(objecttalonario);
  Swal.close()
    if (resultTimbrado === undefined) {
      nfactura.current = "";
    } else {
      var f1 = new Date(resultTimbrado.fechaFinal);

      if (f1 < new Date()) {
        Swal.fire({
          title: "Talonario Vencido",
          html: `favor de reportar e Inhabilitar`,
          icon: "warning",
          confirmButtonColor: "#00273a",
        });
        navigate("/Opcionesdetimbrado");
      } else {
        nfactura.current = resultTimbrado.numeroActual;
        if (nfactura.current > resultTimbrado.numeroFinal) {
          Swal.fire({
            title: "Talonario Sin Facturas",
            html: `favor de reportar e Inhabilitar`,
            icon: "warning",
            confirmButtonColor: "#00273a",
          });
          navigate("/Opcionesdetimbrado");
        } else {
          if (nfactura.current == resultTimbrado.numeroFinal) {
            Swal.fire({
              title: "Ultima Factura del talonario",
              html: `favor de reportar`,
              icon: "warning",
              confirmButtonColor: "#00273a",
            });
          }
        }
      }

      timbradoid.current = resultTimbrado.idtimbrado;
    }
    setValue({
      ...formData3,
      timbrado: Tim.current,
      idtimbrado: timbradoid.current,
      pf1: Suc.current,
      pf2: PDV.current,
      numeroActual: nfactura,
      moneda: "Gs",
    });
  });
  const pdvID = useCallback(async (e) => {
    PDV.current = e.value;
    Swal.fire({
      title: 'Cargando',
      html: 'Cargando  datos',// add html attribute if you want or remove
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
     
  });
    const objecttalonario = { Suc, PDV, Tim };
    const resultTimbrado = await getTimbradoByQuery(objecttalonario);
    Swal.close()
    if (resultTimbrado === undefined) {
      nfactura.current = "";
    } else {
      var f1 = new Date(resultTimbrado.fechaFinal);

      if (f1 < new Date()) {
        Swal.fire({
          title: "Talonario Vencido",
          html: `favor de reportar e Inhabilitar`,
          icon: "warning",
          confirmButtonColor: "#00273a",
        });
        navigate("/Opcionesdetimbrado");
      } else {
        nfactura.current = resultTimbrado.numeroActual;
        if (nfactura.current > resultTimbrado.numeroFinal) {
          Swal.fire({
            title: "Talonario Sin Facturas",
            html: `favor de reportar e Inhabilitar`,
            icon: "warning",
            confirmButtonColor: "#00273a",
          });
          navigate("/Opcionesdetimbrado");
        } else {
          if (nfactura.current == resultTimbrado.numeroFinal) {
            Swal.fire({
              title: "Ultima Factura del talonario",
              html: `favor de reportar`,
              icon: "warning",
              confirmButtonColor: "#00273a",
            });
          }
        }
      }

      timbradoid.current = resultTimbrado.idtimbrado;
    }
    setValue({
      ...formData3,
      timbrado: Tim.current,
      idtimbrado: timbradoid.current,
      pf1: Suc.current,
      pf2: PDV.current,
      numeroActual: nfactura,
      moneda: "Gs",
    });
  });
  const [Rucval, setRuval] = useState([]);
  const [Rzval, setRzval] = useState([]);
  const clienteid = useCallback(async (e) => {
    const { value, label } = e;
    Swal.fire({
      title: 'Cargando',
      html: 'Cargando  datos',// add html attribute if you want or remove
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
     
  });
    const resultCliente = await getClienteById(value);
    Swal.close()
    const { ruc, razonsocial,direccion,telefono } = resultCliente[0];
    setRuval(ruc);
    setRzval(razonsocial);
    if (direccion!=null)
    {
    if (direccion.length>45)
    {
      Swal.fire({
        title: "Direccion Erronea",
        html: `Dirrecion Muy larga`,
        icon: "error",
        confirmButtonColor: "#00273a",
      });
      navigate(
        '/FichaCliente',
        {
          state: {
           id: value
          }
        }
      )
    }
  }
    setValue({
      ...formData3,
      idCliente: value,
      ruc: ruc,
      cliente: label,
      razonsocial: razonsocial,
      direccion:direccion,
      telefono:telefono
    });
  });

  const [formData3, setValue] = useState([]);

  const ReturnFactura = async ({datosfactura,detallefactura,isCredito,TextoNumber}) => {
    
    setValue((prevState) => {
      const currentData = prevState;
      currentData["detalleFactura"] = rowsData;
      return currentData;
    });
    dispatchReducer({ type: "END" });
    if (nfactura.current != "") {
      try {
        const result = await insertFactura(formData3, rowsData);
          if (result) {
            Swal.fire({
              title: "Factura Guardada",
              html: `Se Guardo Correctamente`,
              icon: "success",
              confirmButtonColor: "#00273a",
            });
            console.log(datosfactura)
            let sum1=0
            let sum2=0
            datosfactura.gravado05=0
            datosfactura.gravado10=0
            genPDF({datosfactura,detallefactura,isCredito,TextoNumber,sum1,sum2})
            navigate("/Facturas");
          }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err}`,
        });
      }
    } else
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `No Existe Talonario`,
        confirmButtonColor: "#00273a",
      });
  };
  const [listCostos, setListCostos] = useState([]);
  const [listTimbrado, setListTimbrado] = useState([]);
  const [listFormadepago, setListFormadepago] = useState([]);
  const [listCliente, setListCliente] = useState([]);
  const [listSucursal, setListSucursal] = useState([]);
  const [lisPDV, setListPDV] = useState([]);
  const ivatotalfactura  = useRef(0);
  const totalfactura = useRef(0);

  // Calcular
  const  Refresh = () => {
    console.log(formData3)
    if (formData3.descuento === undefined) {
      formData3.descuento = 0;
    }
    const _ = require("lodash");
    
    const sum = _.sumBy(rowsData, (rowsDatas) => Number(rowsDatas.total));
    const subtotal5 = _.sumBy(rowsData, (rowsDatas) => Number(rowsDatas.cinco));
    const subtotal10 = _.sumBy(rowsData, (rowsDatas) => Number(rowsDatas.diez));
    let i;
    let iva10 = subtotal10/11;
    let iva5 = subtotal5/11;
   
    
    descuentototal.current=_.sumBy(rowsData, (rowsDatas) => Number(rowsDatas.montodescuento));
    totalfactura.current=(sum - (sum * formData3.descuento) / 100);
    Ivaten.current=(Math.round(iva10));
    Ivafive.current=(Math.round(iva5));
    ivatotalfactura.current=(Math.round(iva5)+Math.round(iva10));
    setValue({
      ...formData3,
      totalmonto: sum - (sum * formData3.descuento) / 100,
      descuentomonto: (sum * formData3.descuento) / 100,
      iva10: Math.round(iva10),
      iva5: Math.round(iva5),
      ivatotal: Math.round(iva5)+Math.round(iva10),
      Subtotal5:subtotal5,
      Subtotal10:subtotal10,
    });
  
    
  };

  useEffect(() => {
    (async () => {
      try {
        Swal.fire({
          title: 'Cargando',
          html: 'Cargando  datos',// add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
         
      });
        const listTimbrados = await getAllTimbrados();
        const listResult2 = await getAllFormaPago();
        const listResult3 = await getAllClientes();
        const listResult4 = await getAllSucursal();
        const listResult5 = await getAllPuntoVenta();
        const listResult6 = await getAllCostos();
        const resultfive = await  getUserCredentials();
        if (resultfive.IdUsuario===79)
        {
          navigate("/Facturas");
        }
         
        const mapeoCostos = listResult6.map((item) => {
          return {
            value: item.idcostos,
            label: item.COD + " " + item.producto,
          };
        });
        setListCostos(mapeoCostos);

        const resulting = listTimbrados.reduce((acc, item) => {
          if (!acc.includes(item.timbrado)) {
            acc.push(item.timbrado);
          }
          return acc;
        }, []);

        const mapeoTimbrado = resulting.map((item) => {
          return { value: item, label: item };
        });

        setListTimbrado(mapeoTimbrado);

        const resultMapeado = listResult2.map((item) => {
          return { label: item.nombre, value: item.nombre };
        });
        setListFormadepago(resultMapeado);
        const resultMapeado2 = listResult3.map((item) => {
          return { label: item.nombre+" "+item.ruc, value: item.idClientes };
        });
        setListCliente(resultMapeado2);
        const resultMapeado3 = listResult4.map((item) => {
          return { label: item.codigo + " " + item.nombre, value: item.codigo };
        });
        setListSucursal(resultMapeado3);
        const resultMapeado5 = listResult5.map((item) => {
          return { label: item.codigo + " " + item.nombre, value: item.codigo };
        });
        setListPDV(resultMapeado5);
      } catch (err) {
        console.log(err);
      }
      fdp.current="Efectivo"
      tp.current="Contado"
      Swal.close();
    })();
  }, []);
  const ComprobarComprobante = (fdp,tp) => {
   if (tp=="Credito")
   {
      setComprobante(true)
      Compro.current.value=""
      setValue({
        ...formData3,
        numerodecomprobante:"NO"
      });
      
   }else if (fdp=="Efectivo")
    {
      setComprobante(true)
      Compro.current.value=""
      setValue({
        ...formData3,
        numerodecomprobante:"NO"
      });
    }
    else {
      setComprobante(false)

    }


  }
  

  const onReturnDataCliente = async (data) => {
    try {
      Swal.fire({
        title: 'Cargando',
        html: 'Cargando  datos',// add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
       
    });
      const result = await saveCliente({ ...data, isNew: true });
      Swal.close()
      if (result) {
        if (result) {
          Swal.fire({
            title: "Cliente Cargado",
            html: `Se cargo correctalmente el cliente`,
            icon: "success",
            confirmButtonColor: "#00273a",
          });
          const listResult3 = await getAllClientes();
          const resultMapeado2 = listResult3.map((item) => {
            return { label: item.nombre+" "+item.ruc, value: item.idClientes };
          });
          setListCliente(resultMapeado2);
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };


  return (
    <form >
      <Row>
        <Col>
          <Card className="p-4 p-xl-5 my-3 my-xl-4">
            <Row>
              <Col lg={4} md={4} sm={4}>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <div className="mb-2">
                      <span className="fw-bold">Fecha: &nbsp;</span>
                      <span className="current-date">
                        {new Date().toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">Timbrado: </span>
                  <Select
                    className="basic-single"
                    options={listTimbrado}
                    onChange={(e) => {
                      timbradoID(e);
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">Sucursal: </span>
                  <Select
                    className="basic-single"
                    options={listSucursal}
                    onChange={(e) => {
                      sucursalID(e);
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">Punto de Venta: </span>
                  <Select
                    className="basic-single"
                    options={lisPDV}
                    onChange={(e) => {
                      pdvID(e);
                    }}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} sm={1}>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">Termino de Pago:&nbsp;</span>
                  <Select
                    className="basic-single"
                    options={TYPE_FACTURA}
                    defaultValue={{ label: "Contado", value: "Contado" }}
                    onChange={(e) => {
                     
                      tp.current=e.value
                      ComprobarComprobante(fdp.current,tp.current)
                      setValue({
                        ...formData3,
                        tipoFactura: e.value,
                      });
                      

                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">
                    Fecha de Vencimiento:&nbsp;
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      
                      setValue({
                        ...formData3,
                        fechapagovencimiento: e.target.value,
                      });
                    }}
                  />
                </div>
                {  (formData3.tipoFactura=="Credito") &&
                  <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">
                    Recibo:&nbsp;
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setValue({
                        ...formData3,
                        recibocredito: e.target.value,
                      });
                    }}
                  />
                </div>
                }
              
              </Col>
              <Col lg={4} md={4} sm={1}>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">Forma de Pago:&nbsp;</span>
                  <Select
                    options={listFormadepago}
                    defaultValue={{ label: "Efectivo", value: "Efectivo" }}
                    onChange={(e) => {
                      setValue({
                        ...formData3,
                        formadepago: e.value,
                      });
                     fdp.current=e.value
                        ComprobarComprobante(fdp.current,tp.current)
                     
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">
                    Numero de &nbsp;Comprobante:&nbsp;
                  </span>
                  <input
                    type="text"
                    ref={Compro}
                    className="form-control"
                    disabled={Comprobante}
                    onChange={(e) => {
                      setValue({
                        ...formData3,
                        numerodecomprobante: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">
                    Obs.
                  </span>
                  <textarea
                    className="form-control"
                    onChange={(e) => {
                      setValue({
                        ...formData3,
                       obs: e.target.value,
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex flex-row align-items-center">
              <div className="mt-4">
                <span style={{ fontSize: 20 }} className="fw-bold">
                  Numero de Factura: &nbsp;
                </span>
                <span style={{ fontSize: 20 }} className="fw-bold">
                  {Suc.current}-{PDV.current}-{nfactura.current}
                </span>
              </div>
            </div>
            <hr className="my-4" />
            <Row className="mb-5">
              <Col>
                <Form.Label className="fw-bold mb-2">Factura a:</Form.Label>
                <Row className="mb-2">
                  <Col>
                    {" "}
                    <Select
                      options={listCliente}
                      openOnClick={false} 
                      onChange={(e) => {
                        clienteid(e);
                      }}
                    />
                  </Col>
                  <Col>
                    {" "}
                    <Button onClick={handleShow4} size="sm">
                      Agregar Cliente
                    </Button>
                  </Col>
                </Row>
                <input
                  type="text"
                  className="form-control mb-2"
                  required
                  placeholder="Razon social"
                  value={Rzval}
                  disabled
                />
                <input
                  type="text"
                  className="form-control "
                  required
                  placeholder="Ruc"
                  value={Rucval}
                  disabled
                />
              </Col>

              <div className="row">
                <div className="col">
                  <table className="table">
                    <thead>
                      <tr>
                      <th style={{ color: "black" ,width:"11%"}}>Dcto.</th>
                        <th style={{ color: "black",width:"11%" }}>Cant.</th>
                        <th style={{ color: "black" }}>
                          {" "}
                          Cod. Producto/Servicio
                        </th>
                        <th style={{ color: "black" }}>Precio</th>
                        <th style={{ color: "black",width:"11%" }}>Iva</th>
                        <th style={{ color: "black" }}>Total</th>
                        <th>
                          <Button size="sm" onClick={addTableRows}>
                            +
                          </Button>
                        </th>
                      </tr>
                    </thead>

                    <TableRows

                      Descuento={Descuento}
                      rowsData={rowsData}
                      deleteTableRows={deleteTableRows}
                      handleChange={handleChange}
                      handleChange2={handleChange2}
                      listcostos={listCostos}
                      handleChangeSelect={handleChangeSelect}
                      Precio={Precio}
                      Ivaproducto={Ivaproducto}
                    />
                  </table>
                </div>
              </div>
            </Row>

            <Row className="mt-4 mb-3 justify-content-start">
            <hr className="my-4" />
              <Col lg={6}>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Descuento:</span>
                  <span>
                    <span className="small ">{descuentototal.current}</span>
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Iva 10:</span>
                  <span>
                    <span className="small ">{Ivaten.current}</span>
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Iva 5:</span>
                  <span>
                    <span className="small ">{Ivafive.current}</span>
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Iva Total:</span>
                  <span>
                    <span className="small ">{ivatotalfactura.current}</span>
                  </span>
                </div>
                <hr />
                <div
                  className="d-flex flex-row align-items-start justify-content-between"
                  style={{
                    fontSize: "1.125rem",
                  }}
                >
                  <span className="fw-bold">Total:</span>
                  <span className="fw-bold">{totalfactura.current}</span>
                </div>
              </Col>
            </Row>
            <Button
              variant="primary"
              className="d-block w-100 mb-3"
              onClick={handleShow5}
            >
              Generar Factura
            </Button>
          </Card>
        </Col>
       
      </Row>
      {reducer.isShow && (
        <ModalAgregarClientes
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnDataCliente}
          Editdata={Editdata}
        />
      )}
      {reducer.isShow2 && (
        <ModalPreview
          estado={reducer.isShow2}
          onCloseModal={onCloseModal}
          onReturnValue2={ReturnFactura}
          detallefactura={rowsData}
          datosfactura={formData3}
          contado={Contado}
          credito={Credito}
        
        />
      )}
    </form>
  );
};
export default Generarfactura;
