import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllfacturasCreditoPagados(queryObject) {
  
  const fechafinal = queryObject.fechafinal;
  const fechainicial = queryObject.fechaincial;
  const formadepago = queryObject.formadepago;
  const token = getAuthToken();
  const result = await fetch(
    `${CALL_API2}/facturacreditopagados-query?FI=${fechainicial}&FF=${fechafinal}&FDP=${formadepago}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }
  return data.result;
}
export async function getAllFacturasCreditoNodepositados() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura/CreditoNodepositados`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getAllFacturasContadoNodepositados() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura/Nodepositados`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getAllFacturacion() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getAllFacturasbyDate(queryObject) {
 
  const fechafinal = queryObject.fechafinal;
  const fechainicial = queryObject.fechaincial;
  const token = getAuthToken();
  const result = await fetch(
    `${CALL_API2}/facturabydate-query?FI=${fechainicial}&FF=${fechafinal}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }
  return data.result;
}
export async function getAllfacturasContadoPagados(queryObject) {
  
  const fechafinal = queryObject.fechafinal;
  const fechainicial = queryObject.fechaincial;
  const formadepago = queryObject.formadepago;
  const token = getAuthToken();
  const result = await fetch(
    `${CALL_API2}/facturacontadopagados-query?FI=${fechainicial}&FF=${fechafinal}&FDP=${formadepago}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function getFacturasByQuery(paramObject) {
  const token = getAuthToken();
  const fechaInicio = paramObject.fechaInicio;
  const fechaFin = paramObject.fechaFin;
  const result = await fetch(
    `${CALL_API2}/facturas-query?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await result.json();
  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getFacturasComprasByQuery(paramObject) {
  const token = getAuthToken();
  const fechaInicio = paramObject.fechaInicio;
  const fechaFin = paramObject.fechaFin;
  const result = await fetch(
    `${CALL_API2}/facturasCompras-query?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await result.json();
  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getFacturaById(idFactura) {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura/${idFactura}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getDetalleFacturaId(idFactura) {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/Detallefactura/${idFactura}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getAllDetalleCompra() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/DetallefacturaCompra`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getAllDetalleVenta() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/DetallefacturaVenta`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getDetalleFacturaCompraId(idFactura) {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/DetallefacturaCompra/${idFactura}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getFacturaByClienteId(idCliente) {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura/historial/${idCliente}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getFacturaCredByClienteId(idCliente) {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura/facturacred/${idCliente}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function insertFactura(facturaObject, detailData) {
 
  const token = getAuthToken();
  const {
    cliente,
    descuento,
    descuentomonto,
    fechapagovencimiento,
    formadepago,
    iva10,
    iva5,
    idCliente,
    ivatotal,
    moneda,
    numeroActual,
    numerodecomprobante,
    pf1,
    pf2,
    razonsocial,
    ruc,
    timbrado,
    idtimbrado,
    tipoFactura,
    totalmonto,
    obs,
    detalleFactura,
    recibocredito
  } = facturaObject;
  const result = await fetch(`${CALL_API2}/factura`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cliente: cliente,
      descuento: descuento,
      descuentomonto: descuentomonto,
      fechapagovencimiento: fechapagovencimiento,
      formadepago: formadepago,
      iva10: iva10,
      iva5: iva5,
      idCliente: idCliente,
      ivatotal: ivatotal,
      moneda: moneda,
      numeroActual: numeroActual.current,
      numerodecomprobante: numerodecomprobante,
      pf1: pf1,
      pf2: pf2,
      razonsocial: razonsocial,
      ruc: ruc,
      timbrado: timbrado,
      idtimbrado: idtimbrado,
      tipoFactura: tipoFactura,
      totalmonto: totalmonto,
      obs:obs,
      detalleFactura: detalleFactura,
      recibocredito: recibocredito? recibocredito:""
    }),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function updateFactura(facturaObject) {

  const { valueUpdate, FileUpdate, idFactura } = facturaObject;
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura/${idFactura}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      valueUpdate: valueUpdate,
      FileUpdate: FileUpdate,
      idFactura :idFactura
    }),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function anularFactura(objeto) {
  const { id,motivo } = objeto;
  const idFactura = id
  const motivoanulacion = motivo
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/anular-factura/${idFactura}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      motivoanulacion: motivoanulacion,
      idFactura: idFactura,
    }),
  });
  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isDeleted;
}
export async function anularFacturaCompra(idFactura) {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/factura-compra/${idFactura}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    console.error(data.message);
  }

  return data.message;
}
export async function insertFacturaCompra(facturaObject, detailData) {
 
  const token = getAuthToken();
  const {
    razonsocial,
    ruc,
    fecha,
    numerodefactura,
    terminodepago,
    fechadevencimiento,
    obs,
    iva10total,
    iva5total,
    total,
    detalleFactura,
    destinocompra
  } = facturaObject;
console.log(facturaObject)
  const result = await fetch(`${CALL_API2}/facturacompra`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      razonsocial:razonsocial,
      ruc:ruc,
      fecha:fecha,
      numerodefactura:numerodefactura,
      terminodepago:terminodepago,
      fechadevencimiento:fechadevencimiento,
      obs:obs,
      iva10total:iva10total,
      iva5total:iva5total,
      total:total,
      detalleFactura: detalleFactura,
      destinocompra:destinocompra
    }),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}
export async function getAllFacturacionCompra() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/facturacompra`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function getFacturaCompraById(idFactura) {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/facturacompra/${idFactura}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}